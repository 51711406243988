<template>
  <div>
    <a-form ref="form" layout="vertical" :model="form">
      <a-form-item name="rebateType">
        <a-radio-group v-model:value="form.rebateType" @change='getChange'>
          <a-radio value="PROPORTIONAL_REBATES">比例返佣</a-radio>
          <a-radio value="FIXED_REBATES">固定返佣</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item v-if="form.rebateType != 'FIXED_REBATES'" label="返佣比例" name="proportion"
        :rules="[{ required: true, message: '请输入返佣比例' }]">
        <a-input-number min=0 max=100 @change='getProportionChange' v-model:value="form.proportion"
          :formatter="value => (value == null || value === '') ? value : `${value}`.replace(/(\.\d{2})\d*$/, '$1')">
          <template #addonAfter>%</template>
        </a-input-number>
      </a-form-item>
      <a-form-item :label="form.rebateType == 'FIXED_REBATES' ? '应返金额' : ''" name="amountToBeRefunded"
        :rules="[{ required: true, message: '请输入应返金额' }]">
        <div class="label-style" v-if="form.rebateType == 'PROPORTIONAL_REBATES'" slot="label">
          <div>
            <span class="ls-required">*</span>
            <span>应返金额</span>
          </div>
          <a-popover placement="top">
            <template #content>
              <p class="ls-content">应返金额=订单金额x返佣比例</p>
            </template>
            <question-circle-outlined class="ls-icon" />
          </a-popover>
        </div>
        <a-input-number v-model:value="form.amountToBeRefunded"
          :formatter="value => (value == null || value === '') ? value : `${value}`.replace(/(\.\d{2})\d*$/, '$1')">
          <template #addonAfter>元</template>
        </a-input-number>
      </a-form-item>
      <a-form-item label="备注" name="remarks" :rules="[{ min: 1, max: 200, message: '只能输入1-200个字符' }]">
        <a-textarea :rows="4" v-model:value="form.remarks" placeholder="请输入内容" />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="submit" :loading="submitLoading">确认</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { get } from "@/utils/http";
export default {
  name: 'SaasAdminRebateRulesDetail',
  props: ['selectedRows'],
  data() {
    return {
      submitLoading: false,
      form: { rebateType: 'PROPORTIONAL_REBATES', },
    };
  },

  mounted() {
    if (this.selectedRows[0].rebatesRules.auditStatus == 'RETURNED') {
      this.getData()
    }
  },

  methods: {
    async getData() {
      try {
        let res = await this.$http.get('/order/institutionsOrder/rebateModification/get', { id: this.selectedRows[0].id });
        this.form = res.source;
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    getChange(e) {
      this.form = { rebateType: e.target.value }
    },
    getProportionChange(e) {
      const orderAmount = this.selectedRows[0].orderAmount
      //应返金额
      this.form.amountToBeRefunded = (e / 100) * orderAmount
    },
    async submit() {
      try {
        await this.$refs.form.validate();
      } catch (e) { // 未通过表单验证
        return;
      }
      if (this.form.rebateType == 'FIXED_REBATES') {
        this.form.proportion = 0
      }
      if (this.form.amountToBeRefunded > this.selectedRows[0].orderAmount) {
        this.$message.error('应返金额不能大于订单金额');
      } else {
        this.submitLoading = true;
        try {
          await this.$http.post(this.selectedRows[0].rebatesRules.auditStatus == 'RETURNED' ? '/order/institutionsOrder/rebateModification' : '/order/institutionsOrder/rebatesRules', 
          { 
            id: this.selectedRows[0].id, ...this.form 
          });
          this.$message.success('操作成功');
          this.$refs.form.resetFields();
          this.$emit('close');
        } catch ({ message }) {
          this.$message.error(message);
        }
        this.submitLoading = false;
      }

    }
  },
};
</script>

<style lang="scss" scoped>
.label-style {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.ls-required {
  color: red;
  margin-right: 4px;
}

.ls-icon {
  margin-left: 4px;
  font-size: 14px;
  color: #999999;
}
</style>